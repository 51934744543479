.footerSocialLinks {
  list-style: none;
  display: flex;
  justify-content: var(--footer-social-links-justify-content);
  gap: var(--footer-social-links-gap);
  padding: var(--space-none);
  color: var(--footer-social-icon-color);
}

.footerSocialLink {
  align-items: center;
  display: flex;
  gap: var(--space-6x);

  &:hover,
  &:focus {
    text-decoration-line: none;
  }
}

.footerSocialLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--footer-social-icon-dimension, 32px);
  height: var(--footer-social-icon-dimension, 32px);
  border-radius: 50%;
  cursor: pointer;
  background: var(--footer-social-icon-background);

  &:hover {
    background: var(--footer-social-icon-background-hover);
  }
  &:active {
    background: var(--footer-social-icon-background-active);
  }
}

.socialIcon {
  font-size: var(--font-size-lg);
}
